import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import AuthSimpleLayout from "../layouts/AuthSimpleLayout";
import ErrorLayout from "../layouts/ErrorLayout";
import MainLayout from "../layouts/MainLayout";

import Error404 from "components/errors/Error404";
import Error500 from "components/errors/Error500";

import SimpleForgetPassword from "components/authentication/simple/ForgetPassword";
import SimpleLogin from "components/authentication/simple/Login";
import SimplePasswordReset from "components/authentication/simple/PasswordReset";

import Dashboard from "components/dashboards/default";

import GroupsTable from "components/administration-menu/Groups/GroupsTable";
import AddGroup from "components/administration-menu/Groups/add-group/AddGroup";
import RolesAndPermissionsTable from "components/administration-menu/RolesAndPermissions/RolesAndPermissionsTable";
import AddAssignment from "components/administration-menu/RolesAndPermissions/add-assignment/AddAssignment";
import AddRoleAndPermission from "components/administration-menu/RolesAndPermissions/add-roleAndPermission/AddRoleAndPermission";
import UsersTable from "components/administration-menu/Users/UsersTable";
import AddUser from "components/administration-menu/Users/add-user/AddUser";
import Profile from "components/pages/user/profile/Profile";
import AuthGuard from "./Gaurd/AuthGaurd";

export const CONSTANT_ROUTES = {
  AUTHENTICATION_LOGIN: "/login",
  AUTHENTICATION_REGISTER: "/register",
  AUTHENTICATION_RESET_PASSWORD: "/reset-password",
  AUTHENTICATION_FORGOT_PASSWORD: "/forgot-password",
  USERPROFILE: "/userProfile",
  HOME: "/",
  CATEGORIES_TABLE: "",
  CATEGORY_ADD: "",
  MARKETS_TABLE: "/libraries/markets",
  ADD_NEW_MARKETS: "/libraries/markets/add-new",
  SPACE_ADDRESSES_TABLE: "/libraries/space-addresses",
  ADD_NEW_SPACE_ADDRESS: "/libraries/space-addresses/add-new",
  STATUES_TABLE: "/libraries/statuses",
  ADD_NEW_STATUS: "/libraries/statuses/add-new",
  MEDIUMS_TABLE: "/libraries/mediums",
  ADD_NEW_MEDIUM: "/libraries/mediums/add-new",
  VISIBILITY_TABLE: "/libraries/visibility",
  ADD_NEW_VISIBILITY: "/libraries/visibility/add-new",
  SPACE_CLASSES_TABLE: "/libraries/space-classes",
  ADD_NEW_SPACE_CLASS: "/libraries/space-classes/add-new",
  SPACE_SCORE_TABLE: "/libraries/space-score",
  ADD_NEW_SPACE_SCORE: "/libraries/space-score/add-new",
  VISIBILITY_ANGLES_TABLE: "/libraries/visibility-angles",
  ADD_NEW_VISIBILITY_ANGLES: "/libraries/visibility-angles/add-new",
  DISTANCES_TABLE: "/libraries/distances",
  ADD_NEW_DISTANCE: "/libraries/distances/add-new",
  DESIGN_TYPES: "/libraries/design-types",
  ADD_NEW_DESIGN_TYPE: "/libraries/design-types/add-new",
  MEDIUM_MATERIAL_TYPES_TABLE: "/libraries/material-types",
  ADD_NEW_MEDIUM_MATERIAL_TYPE: "/libraries/material-types/add-new",
  WORK_TYPES_TABLE: "/libraries/work-types",
  ADD_NEW_WORK_TYPE: "/libraries/work-types/add-new",
  PARTIES_TABLE: "/libraries/parties",
  ADD_NEW_PARTY: "/libraries/parties/add-new",
  ADD_NEW_PARTY_CONTACTS: "/libraries/parties/add-new/contact",
  INVENTORY_TABLE: "/ooh/inventory",
  ADD_NEW_INVENTORY: "/ooh/inventory/add-new",
  CITIES_TABLE: "/libraries/cities",
  ADD_NEW_CITY: "/libraries/cities/add-new",
  LOCATION_TABLE: "/libraries/locations",
  ADD_NEW_LOCATION: "/libraries/locations/add-new",
  LOCATION_ADDRESSES_TABLE: "/libraries/location-addresses",
  ADD_NEW_LOCATION_ADDRESS: "/libraries/location-addresses/add-new",
  SCHOOLS_TABLE: "/libraries/schools",
  ADD_NEW_SCHOOL: "/libraries/schools/add-new",
  HORECA_TABLE: "/libraries/horeca",
  ADD_NEW_HORECA: "/libraries/horeca/add-new",
  CONTACTS_TABLE: "/libraries/contacts",
  ADD_NEW_CONTACT: "/libraries/contacts/add-new",
  VENDORS_TABLE: "/libraries/vendors",
  ADD_NEW_VENDOR: "/libraries/vendors/add-new",
  ADD_NEW_VENDOR_CONTACT: "/libraries/vendors/add-new/contact",
  AGENCIES_TABLE: "/libraries/agencies",
  ADD_NEW_AGENCY: "/libraries/agencies/add-new",
  ADD_NEW_AGENCY_CONTACT: "/libraries/agencies/add-new/contact",
  CLIENTS_TABLE: "/libraries/clients",
  ADD_NEW_CLIENT: "/libraries/clients/add-new",
  ADD_NEW_CLIENT_CONTACT: "/libraries/clients/add-new/contact",
  PARTNERS_TABLE: "/libraries/partners",
  ADD_NEW_PARTNER: "/libraries/partners/add-new",
  ADD_NEW_PARTNER_CONTACT: "/libraries/partners/add-new/contact",
  AUTHORITIES_TABLE: "/libraries/authorities",
  ADD_NEW_AUTHORITY: "/libraries/authorities/add-new",
  ADD_NEW_AUTHORITY_CONTACT: "/libraries/authorities/add-new/contact",
};

const FalconRoutes = () => {
  return (
    <Routes>
      {/* <Route path="landing" element={<Landing />} /> */}
      <Route element={<ErrorLayout />}>
        <Route path="errors/404" element={<Error404 />} />
        <Route path="errors/500" element={<Error500 />} />
      </Route>
      {/*- ------------- Authentication ---------------------------  */}

      {/*- ------------- simple ---------------------------  */}
      <Route element={<AuthSimpleLayout />}>
        <Route
          path={CONSTANT_ROUTES.AUTHENTICATION_LOGIN}
          element={<SimpleLogin />}
        />
        {/* <Route
          path="authentication/register"
          element={<SimpleRegistration />}
        /> */}
        <Route
          path={CONSTANT_ROUTES.AUTHENTICATION_FORGOT_PASSWORD}
          element={<SimpleForgetPassword />}
        />

        <Route
          path={CONSTANT_ROUTES.AUTHENTICATION_RESET_PASSWORD}
          element={<SimplePasswordReset />}
        />
        {/* <Route
          path="authentication/lock-screen"
          element={<SimpleLockScreen />}
        />
        <Route path="authentication/logout" element={<SimpleLogout />} />
       
   
        <Route
          path="authentication/confirm-mail"
          element={<SimpleConfirmMail />}
        /> */}
      </Route>

      {/* //--- MainLayout Starts  */}

      <Route element={<AuthGuard />}>
        <Route element={<MainLayout />}>
          {/*Dashboard*/}
          <Route path="/" element={<Dashboard />} />

          <Route path="/groups" element={<GroupsTable />} />
          <Route path="/groups/add-new-group" element={<AddGroup />} />
          <Route
            path="/administration/roles-and-permissions"
            element={<RolesAndPermissionsTable />}
          />
          <Route
            path="/administration/roles-and-permissions/add-new-role"
            element={<AddRoleAndPermission />}
          />
          <Route
            path="/administration/roles-and-permissions/assignment"
            element={<AddAssignment />}
          />
          <Route path="/administration/users" element={<UsersTable />} />
          <Route
            path="/administration/users/add-new-user"
            element={<AddUser />}
          />

          {/*============================Administration End=========================  */}

          {/*=======Pages  ======*/}

          <Route path={CONSTANT_ROUTES.USERPROFILE} element={<Profile />} />
        </Route>
      </Route>

      {/* //--- MainLayout end  */}

      {/* <Navigate to="/errors/404" /> */}
      <Route path="*" element={<Navigate to="/errors/404" replace />} />
    </Routes>
  );
};

export default FalconRoutes;
